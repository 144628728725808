/* In your CSS or SCSS file, for example, src/index.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', Poppins,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

body {
  font-family: 'Poppins', sans-serif;
}


code {
  font-family: 'Poppins', sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;